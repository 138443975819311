/* 
收集所有vuex子模板
*/

import home from './home'
import user from './user'
import search from './search'
import detail from './detail'
import shopcart from './shopcart'
import trade from './trade'

export default {
    home,
    user,
    search,
    detail,
    shopcart,
    trade
}